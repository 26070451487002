import {toast, ToastOptions} from 'react-toastify';
import {User} from "oidc-react";

declare global {
    interface Window {
        _env_:any;
    }
}

const conf = {
  urls: {
    backoffice_main_full: window._env_.REACT_APP_BACKOFFICE_MAIN_FULL_URL,
    backoffice_main: window._env_.REACT_APP_BACKOFFICE_MAIN_URL,
    backoffice_order_overview: window._env_.REACT_APP_BACKOFFICE_ORDER_OVERVIEW_URL,
    backoffice_customer_changes: window._env_.REACT_APP_BACKOFFICE_CUSTOMER_CHANGES_URL,
    serviceUrl: window._env_.REACT_APP_SERVICE_URL,
    orderOverviewAPI: window._env_.REACT_APP_ORDER_OVERVIEW_URL,
    orderOverviewCompanyAPI: window._env_.REACT_APP_ORDER_OVERVIEW_COMPANY_URL,
    synonymOverviewAPI: window._env_.REACT_APP_SYNONYM_OVERVIEW_URL,
    companyOverviewAPI: window._env_.REACT_APP_COMPANY_OVERVIEW_URL,
    fetchCatalogCodesAPI: window._env_.REACT_APP_CATALOG_CODE_URL,
    backoffice_company_overview: window._env_.REACT_APP_BACKOFFICE_COMPANY_OVERVIEW_URL,
  },
  oidcConfig: {
    onSignIn: async (user: User | null) => {
      window.location.href = window._env_.REACT_APP_BACKOFFICE_MAIN_FULL_URL
    },
    onSignOut: () => {
      window.location.reload();
    },
    authority: 'https://ineexa-tmd3ak.zitadel.cloud',
    clientId: '172140324712415489@dev',
    responseType: 'code',
    scope: 'openid profile email offline_access urn:zitadel:iam:user:resourceowner',
    redirectUri: window._env_.REACT_APP_BACKOFFICE_MAIN_FULL_URL,
    post_logout_redirect_uri: window._env_.REACT_APP_BACKOFFICE_MAIN_FULL_URL,
    autoSignIn: true
  },
  permissions: {
    admin: "Admin",
    admin_cart: "Admin_Cart",
    admin_currency: "Admin_Currency",
    admin_price: "Admin_Price",
    admin_availability: "Admin_Availability",
    admin_product: "Admin_Product",
    admin_user: "Admin_User",
    admin_order: "Admin_Order",
  },
  formatting: {
    dateTime: "DD.MM.YYYY, HH:mm:ss",
    time:"HH:mm:ss",
    date:"DD.MM.YYYY"
  },
  messageOptionsSuccess: {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    autoClose: 10000
  } as ToastOptions,
  messageOptionsOther: {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    autoClose:false
  } as ToastOptions,

};
export default conf;
